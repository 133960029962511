import React from "react";
import Home from './Home';
import Projects from './Projects';

function Dashboard(){
    return (
        <div>
          <Home/>
          <Projects/>
        </div>
      );

}

export default Dashboard